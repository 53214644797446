import { ChainId, Token } from '@pancakeswap/sdk'

const mapping = {
  [ChainId.BSC]: 'smartchain',
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.CORE]: 'core',
  [ChainId.ARBITRUM]: 'arbitrum',
  [ChainId.ZKS]: 'zks',
  [ChainId.BASE]: 'base'
}

const getTokenLogoURL = (token?: Token) => {
  if (token && mapping[token.chainId]) {
    return `https://lfgswap.finance/images/tokens/${token.address}.png`
  }
  return null
}

export default getTokenLogoURL
